<template>
    <div id="summary-details">
        <table class="table">
            <tbody v-if="!fetchSummaryDetailLoading">
                <tr>
                    <td class="label">Application ID</td>
                    <td class="value">{{ summaryDetail?.application_id }}</td>
                </tr>
                <tr>
                    <td class="label">Parcel Number</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.situs_parcel_id }}
                        <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'" class="copy-to-clipboard"
                            @click="copyTextToClipboard(summaryDetail?.situs_parcel_id)"
                            v-if="summaryDetail?.situs_parcel_id">
                            <span class="fa-regular fa-copy fa-xs"></span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="label">Situs Address</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.situs_full }}
                        <a href="javascript:;" v-tooltip.bottom="'Copy to Clipboard'" class="copy-to-clipboard"
                            @click="copyTextToClipboard(summaryDetail?.situs_full)" v-if="summaryDetail?.situs_full">
                            <span class="fa-regular fa-copy fa-xs"></span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="label">Applicant</td>
                    <!-- nosemgrep - value is sanitized -->
                    <td class="value text-uppercase" v-html="applicantNamesDisplayHTML"></td>
                </tr>
                <tr>
                    <td class="label">Mail Address</td>
                    <td class="value clipboard-copy-wrapper">
                        {{ summaryDetail?.mail_full }}
                        <span class="clipboard-copy-target" :id="'mail_full_' + summaryDetail?.tru_id || ''"
                            :data-copy-text="summaryDetail?.mail_full"></span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Previous Address</td>
                    <td class="value">{{ summaryDetail?.prev_situs_full }}</td>
                </tr>
                <tr>
                    <td class="label">Exemptions</td>
                    <td class="value">{{ summaryDetail?.exemptions?.join(", ") }}</td>
                </tr>
                <tr>
                    <td class="label">Submission Date</td>
                    <td class="value">{{ formatDate(summaryDetail?.application_timestamp) }}</td>
                </tr>
                <tr>
                    <td class="label">Effective Date</td>
                    <td class="value">{{ formatDate(summaryDetail?.effective_date) }}</td>
                </tr>
                <tr>
                    <td class="label">Status</td>
                    <td class="value text-capitalize">
                        <Chip :label="summaryDetail?.application_status"
                            :class="`tr-chip status-chip-${summaryDetail?.application_status}`" />

                        <template v-if="summaryDetail?.application_status === 'snooze' && summaryDetail?.snooze_until">
                            until {{ formatDate(summaryDetail?.snooze_until) }}
                        </template>
                    </td>
                </tr>
                <tr>
                    <td class="label">Assigned To</td>
                    <td class="value">
                        <Chip v-if="summaryDetail?.assigned_user" :label="summaryDetail?.assigned_user"
                            class="tr-chip assigned-to-chip" />
                        <span v-else>
                            <i>Unassigned</i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td class="label">Labels</td>
                    <td class="value">
                        <Chip v-for="label in summaryDetail?.labels" :key="label" :label="label"
                            class="me-2 mb-1 tr-chip label-chip" />
                    </td>
                </tr>
                <tr v-if="promonMatched.length">
                    <td class="label">Status in Proactive Monitoring</td>
                    <td class="value">
                        <a v-if="promonMatched.length > 1" href="javascript:;"
                            @click="showListOfPromonMatchedModal = true" v-tooltip="'See all'">
                            Matched
                            <Badge :value="promonMatched.length" severity="success" class="p-badge-xs" />
                        </a>
                        <a v-else :href="`/candidate-details/${promonMatched[0].tru_id}`" target="_blank"
                            v-tooltip="'Go to details page'">
                            {{ promonStatus(promonMatched[0]) }}
                            <span class="fa fa-link"></span>
                        </a>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in 8" :key="i">
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td>
                        <Skeleton height="1.2rem" width="250px"></Skeleton>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Skeleton height="1.2rem" width="120px"></Skeleton>
                    </td>
                    <td class="d-flex">
                        <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                        <Skeleton height="1.2rem" width="50px" class="me-2"></Skeleton>
                        <Skeleton height="1.2rem" width="100px" class="me-2"></Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ModalDialog v-if="showListOfPromonMatchedModal" title="Matched Candidates"
        :close="() => showListOfPromonMatchedModal = false">
        <DataTable :value="promonMatched">
            <Column style="width: 5%" field="owners" header="">
                <template #body="slotProps">
                    <a :href="`/candidate-details/${slotProps.data.tru_id}`" target="_blank">
                        <span class="fa fa-external-link-alt"></span>
                    </a>
                </template>
            </Column>
            <Column style="width: 45%" field="owners" header="Owner Name">
                <template #body="slotProps">
                    <div v-if="slotProps.data.owners?.length">
                        <div class="mb-2" v-for="(owner, index) in slotProps.data.owners" :key="index">
                            {{ owner }}
                        </div>
                    </div>
                    <div v-else>-</div>
                </template>
            </Column>
            <Column style="width: 25%" field="queue" header="Queue">
                <template #body="slotProps">
                    <div v-if="slotProps.data.queue">
                        {{ ucwords(slotProps.data.queue) }}
                    </div>
                    <div v-else>-</div>
                </template>
            </Column>
            <Column style="width: 25%" field="effective_date" header="Effective Date">
                <template #body="slotProps">
                    <div v-if="slotProps.data.effective_date">
                        {{ formatDate(slotProps.data.effective_date) }}
                    </div>
                    <div v-else>-</div>
                </template>
            </Column>
        </DataTable>
    </ModalDialog>
</template>

<script setup lang="ts">
import type { SummaryDetail } from "@/helpers/interface/appvet"
import type { AxiosError } from "axios"
import { computed, onMounted, ref } from "vue"
import { useAuth0 } from "@auth0/auth0-vue"
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import Chip from 'primevue/chip'
import {
    convertLineBreaksToHTML,
    getApiErrorMessage,
    formatDate,
    sanitizeHTML,
    copyTextToClipboard,
    ucwords,
    QueueCode
} from "@/helpers/common"
import { toast } from "@/helpers/toast"
import Badge from "primevue/badge"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import type { CandidateListNew } from "@/helpers/interface/candidates"

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})
const emits = defineEmits(["summaryLoaded"])
const api = useAPI()
const { user } = useAuth0()
const fetchSummaryDetailLoading = ref(true)
const summaryDetail = ref<SummaryDetail | null>(null)
const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}
const fetchSummaryDetail = async () => {
    fetchSummaryDetailLoading.value = true

    try {
        const { data } = await api.get(`applications/${props.tru_id}/detail`)
        summaryDetail.value = data
        searchForPromonLink(data.situs_parcel_id)
        markApplicationSeen(data)
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }

    fetchSummaryDetailLoading.value = false
    emits("summaryLoaded")
}

const applicantNamesDisplayHTML = computed(() => {
    const names = summaryDetail.value?.applicants_names
    if (!names) {
        return ""
    }
    return sanitizeHTML(convertLineBreaksToHTML(names))
})

const promonMatched = ref<any[]>([])
const promonMatchedCount = ref("")
const showListOfPromonMatchedModal = ref(false)
const searchForPromonLink = async (parcelNum: string) => {
    try {
        const payload = {
            "filter": [
                { "field": "parcel_num", "type": "=", "value": parcelNum },
                { "field": "active", "type": "=", "value": true },
            ],
            "page": 1,
            "page_size": 25,
            "sort": [],
            "include_total": false
        }
        const response = await api.post("/taxroll/list", payload)
        promonMatchedCount.value = response.data.data?.length || 0
        promonMatched.value = response.data.data || []
    }
    catch (error: any) {
        console.log(getApiErrorMessage(error))
    }
}

const promonStatus = (promon: CandidateListNew) => {
    switch (promon.queue) {
        case QueueCode.Inbox:
        case QueueCode.InvestigationReports:
        case QueueCode.InvestigateNow:
            return "Flagged, Awaiting Review"
        case QueueCode.Monitor:
            return "Reviewed"
        case QueueCode.Questionnaire:
            return "Questionnaire Sent"
        case QueueCode.Snooze:
            return "Snoozed"
        case QueueCode.Unqualified:
            return "Unqualified"
        case QueueCode.Archive:
            return ucwords(promon.final_outcome)
        default:
            return "None"
    }
}


const markApplicationSeen = (data: SummaryDetail) => {
    if (data && data.tru_id && user.value?.sub === data.assigned_user_id) {
        api.post("applications/seen", { "tru_ids": [data.tru_id] })
    }
}

onMounted(() => fetchSummaryDetail())
defineExpose({ fetchSummaryDetail })
</script>