<template>
    <div>
        <TheHeader />
        <div id="applicationDetails" class="ms-3">
            <div v-if="enableToolbar" class="m-2">
                <div class="row" id="page-header-action-section">
                    <div class="col">
                        <Button type="button" class="me-2" @click="update.status.value = 'approved'">
                            <span class="fa fa-check me-2" aria-hidden="true"></span> Approve
                        </Button>
                        <Button type="button" class="me-2" @click="update.status.value = 'denied'" severity="danger">
                            <span class="fa fa-times me-2" aria-hidden="true"></span> Deny
                        </Button>
                        <Button type="button" class="me-2" @click="update.status.value = 'snooze'"
                            severity="secondary">
                            <span class="fa fa-clock me-2" aria-hidden="true"></span> Snooze
                        </Button>
                        <SplitButton label="Assign" rounded class="me-2" :model="assignActionItems"
                            id="assign-action-item" @click="() => selectedAction = 'Assign'">
                            <span class="fa fa-user-plus me-2" aria-hidden="true"></span>
                            Assign
                        </SplitButton>
                        <SplitButton label="Labels" rounded class="me-2" :model="labelActionItems"
                            id="label-action-item" @click="() => selectedAction = 'Add Label'" severity="info">
                            <span class="fa fa-tags me-2" aria-hidden="true"></span>
                            Labels
                        </SplitButton>
                        <Button type="button" class="me-2" id="note-action-item"
                            @click="() => note.showNoteDialog.value = true" severity="info">
                            <span class="fa fa-comment me-2" aria-hidden="true"></span> Note
                        </Button>
                    </div>
                </div>
            </div>
            <div class="mt-1">
                <div class="row p-component ms-2">
                    <div class="col-5">
                        <div id="summary-pane">
                            <div id="summary-header-title" class="p-2">
                                <span class="p-tabview-title">Summary</span>
                            </div>
                            <ApplicationVetterSummaryDetails :tru_id="tru_id" ref="summaryDetailsComponent"
                                @summary-loaded="onSummaryLoaded" />
                        </div>
                        <div id="party-parcel-pane" :style="'height:' + (leftPanelHeight) + 'px; overflow-y: auto;'"
                            ref="leftPanelRef">
                            <Tabs value="0">
                                <TabList class="sticky-tablist">
                                    <Tab value="0">Parties</Tab>
                                    <Tab value="1">Parcel</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel value="0">
                                        <ApplicationVetterParties :tru_id="tru_id" />
                                    </TabPanel>
                                    <TabPanel value="1">
                                        <ApplicationVetterParcel :tru_id="tru_id" />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                        </div>
                    </div>
                    <div class="col-7" :style="'height:' + (rightPanelHeight) + 'px; overflow-y: auto;'"
                        ref="rightPanelRef">
                        <div id="investigation-pane">
                            <Tabs value="0">
                                <TabList>
                                    <Tab value="0">Flags</Tab>
                                    <Tab value="1">Documents</Tab>
                                    <Tab value="2">History</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel value="0">
                                        <ApplicationVetterFlagsNotes :tru_id="tru_id" />
                                    </TabPanel>
                                    <TabPanel value="1">
                                        <ApplicationVetterDocumentList :tru_id="tru_id" />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <ApplicationVetterHistoryList :tru_id="tru_id" ref="historyListComponent" />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalDialog v-if="showUpdateStatusDialog" :title="`${ucfirst(update.statusText.value)} this application?`"
        :close="closeUpdateStatusDialog">
        <template v-if="update.status.value === 'snooze'">
            <div class="element-container">
                <label for="snooze-until-datepicker" class="form-label form-label-required">Snooze Until</label>
                <DatePicker id="snooze-until-datepicker" class="d-flex" required v-model="update.snoozeUntilDate.value" />
            </div>
        </template>
        <Textarea v-model="update.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitUpdateStatus(update.status.value)" id="update-status"
                :disabled="update.loading.value">
                <span class="fa fa-edit me-2" aria-hidden="true" v-if="!update.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="update.loading.value"></span>
                Submit
            </Button>
            <Button @click="closeUpdateStatusDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="assign?.showAssignDialog.value" :title="`Assign this application to`" :close="closeAssignDialog">
        <Select :scrollHeight="PV_SCROLL_HEIGHT" v-model="assign.user.value" :options="userList" optionLabel="name" placeholder="-- Assign User --"
            class="d-flex" />
        <br>
        <Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitAssign('assign')" id="assign" :disabled="assign.loading.value">
                <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!assign.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="assign.loading.value"></span>
                Assign
            </Button>
            <Button @click="closeAssignDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="assign?.showUnassignDialog.value" :title="`Unassign this application`"
        :close="closeAssignDialog">
        <Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitAssign('unassign')" id="unassign" :disabled="assign.loading.value" severity="warning">
                <span class="fa fa-user-times me-2" aria-hidden="true" v-if="!assign.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="assign.loading.value"></span>
                Unassign
            </Button>
            <Button @click="closeAssignDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="assign?.showAssignToMeDialog.value" :title="`Assign this application to me`"
        :close="closeAssignDialog">
        <Textarea v-model="assign.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitAssign('assigntome')" id="assign-to-me" :disabled="assign.loading.value">
                <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!assign.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="assign.loading.value"></span>
                Assign To Me
            </Button>
            <Button @click="closeAssignDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="label?.showAddDialog.value" :title="`Add labels to this application`" :close="closeLabelDialog">
        <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
            v-model="label.add.value" multiple optionLabel="label_name" :options="labelList" class="d-flex" />
        <br>
        <Textarea v-model="label.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitLabel('add')" id="add-label" :disabled="label.loading.value">
                <span class="fa fa-plus-circle me-2" aria-hidden="true" v-if="!label.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="label.loading.value"></span>
                Add
            </Button>
            <Button @click="closeLabelDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="label?.showRemoveDialog.value" :title="`Remove labels from this application`"
        :close="closeLabelDialog">
        <MultiSelect :show-toggle-all="false" placeholder="-- Select Label --" display="chip" required
            v-model="label.remove.value" multiple optionLabel="label_name" :options="labelList" class="d-flex" />
        <br>
        <Textarea v-model="label.optionalNote.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitLabel('remove')" id="remove-label" severity="warning" :disabled="label.loading.value">
                <span class="fa fa-trash me-2" aria-hidden="true" v-if="!label.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="label.loading.value"></span>
                Remove
            </Button>
            <Button @click="closeLabelDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>

    <ModalDialog v-if="note?.showNoteDialog.value" :title="`Add note to this application`" :close="closeNoteDialog">
        <Textarea v-model="note.note.value" :maxlength="MAX_NOTE_LENGTH" placeholder="Add note" />
        <template #footer>
            <Button @click="submitNote" id="note" :disabled="note.loading.value">
                <span class="fa fa-edit me-2" aria-hidden="true" v-if="!note.loading.value"></span>
                <span class="fa fa-spinner fa-spin me-2" aria-hidden="true" v-if="note.loading.value"></span>
                Submit
            </Button>
            <Button @click="closeNoteDialog" severity="secondary">
                <span class="fa fa-times-circle me-2" aria-hidden="true"></span> Close
            </Button>
        </template>
    </ModalDialog>
    <AppMonitor />
</template>

<script setup lang="ts">
import { useRoute } from "vue-router"
import { toast } from "@/helpers/toast"
import { useAPI } from "@/helpers/services/api"
import { onMounted, ref, computed, onUnmounted, watch } from "vue"
import ModalDialog from "@/components/Shared/ModalDialog.vue"
import {
    APPVET_DETAILS_VIEW_ONLY_PATH,
    MAX_NOTE_LENGTH,
    calculateScrollHeight,
    getApiErrorMessage,
    ucfirst,
    PV_SCROLL_HEIGHT,
    InternalSettings
} from "@/helpers/common"
import { logPageView } from "@/helpers/insights"
import type { Label } from "@/helpers/interface/candidates"
import AppMonitor from "@/components/AppMonitor.vue"
import { useAdminDetails } from "@/stores/adminDetails";
import { useProfile } from "@/stores/profile"
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import Textarea from 'primevue/textarea'
import DatePicker from "primevue/datepicker"
import type { Auth0User } from "@/helpers/interface/admin-page"
import Select from 'primevue/select'
import MultiSelect from 'primevue/multiselect'
import TheHeader from "@/components/TheHeader.vue";
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import ApplicationVetterSummaryDetails from "@/components/ApplicationVetter/ApplicationVetterSummaryDetails.vue"
import ApplicationVetterParties from "@/components/ApplicationVetter/ApplicationVetterParties.vue"
import ApplicationVetterParcel from "@/components/ApplicationVetter/ApplicationVetterParcel.vue"
import ApplicationVetterFlagsNotes from "@/components/ApplicationVetter/ApplicationVetterFlagsNotes.vue"
import ApplicationVetterHistoryList from "@/components/ApplicationVetter/ApplicationVetterHistoryList.vue"
import ApplicationVetterDocumentList from "@/components/ApplicationVetter/ApplicationVetterDocumentList.vue"
import { useAuth0 } from '@auth0/auth0-vue';
import {
    performLabelUpdate,
    performAssignAction,
    performUpdateStatusAction,
    performNoteAction
} from "@/helpers/bulkActions"
import { ClaimsFields } from "@/helpers/roles"
import { useTaxroll } from "@/stores/taxroll"
import dayjs from "dayjs"

type StatusCode = "approved" | "denied" | "snooze"
const { user } = useAuth0();
const storeAdminDetails = useAdminDetails();
const api = useAPI()
const taxroll = useTaxroll()
const route = useRoute()
const storeProfile = useProfile();
const tru_id = route?.params?.tru_id as string

/*** Update Status ***/
const update = {
    status: ref<StatusCode | "">(""),
    optionalNote: ref(""),
    snoozeUntil: ref<string>(""),
    snoozeUntilDate: ref<Date | null>(null),
    loading: ref(false),
    statusText: computed(() => {
        switch (update.status.value) {
            case "approved":
                return "Approve";
            case "denied":
                return "Deny";
            case "snooze":
                return "Snooze";
            default:
                return "";
        }
    }),
    parentURL: "/applications"
}
const selectedAction = ref("")
const showUpdateStatusDialog = computed(() => (update.status.value !== ""))
const submitUpdateStatus = async (status: string) => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performUpdateStatusAction(api, status, update, additionalParam)
    if (result === "success") {
        refreshData()
        closeUpdateStatusDialog()
    }
    update.loading.value = false
}

const closeUpdateStatusDialog = () => {
    selectedAction.value = ""
    update.status.value = ""
    update.optionalNote.value = ""
}

/*** Assign ***/
const assignActionItems = [
    { label: 'Assign', command: () => selectedAction.value = "Assign" },
    { label: 'Unassign', command: () => selectedAction.value = "Unassign" },
    {
        label: 'Assign to me',
        command: () => selectedAction.value = "Assign To Me",
        disabled: !(user.value?.[ClaimsFields['app_metadata']]?.is_client)
    },
]
const assign = {
    sub: user.value?.sub,
    user: ref<Auth0User | null>(null),
    optionalNote: ref(""),
    loading: ref(false),
    showAssignDialog: computed(() => selectedAction.value === "Assign"),
    showAssignToMeDialog: computed(() => selectedAction.value === "Assign To Me"),
    showUnassignDialog: computed(() => selectedAction.value === "Unassign"),
    parentURL: "/applications"
}
const userList = computed(() => storeAdminDetails.getUsersList)
const fetchUsersList = async () => {
    await storeAdminDetails.fetchUsersList(loadFailureHandler)
}
const submitAssign = async (action: "assign" | "unassign" | "assigntome") => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performAssignAction(api, action, assign, additionalParam)
    if (result === "success") {
        refreshData()
        closeAssignDialog()
    }
    assign.loading.value = false

}
const closeAssignDialog = () => {
    selectedAction.value = ""
    assign.user.value = null
    assign.optionalNote.value = ""
}

/*** Label ***/
const labelActionItems = [
    { label: 'Add', command: () => selectedAction.value = "Add Label" },
    { label: 'Remove', command: () => selectedAction.value = "Remove Label" },
]
const label = {
    add: ref<Label[]>([]),
    remove: ref<Label[]>([]),
    optionalNote: ref(""),
    loading: ref(false),
    showAddDialog: computed(() => selectedAction.value === "Add Label"),
    showRemoveDialog: computed(() => selectedAction.value === "Remove Label"),
    parentURL: "/applications"
}
const labelList = ref<Label[]>([])
const submitLabel = async (action: "add" | "remove") => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performLabelUpdate(api, action, label, additionalParam)
    if (result === "success") {
        refreshData()
        closeLabelDialog()
    }
    label.loading.value = false
}
const closeLabelDialog = () => {
    selectedAction.value = ""
    label.add.value = []
    label.remove.value = []
    label.optionalNote.value = ""
}

/*** Note ***/
const note = {
    note: ref(""),
    loading: ref(false),
    showNoteDialog: ref(false),
    parentURL: "/applications"
}
const submitNote = async () => {
    const additionalParam = { tru_ids: [tru_id] }
    const result = await performNoteAction(api, note, additionalParam)
    if (result === "success") {
        refreshData(false)
        closeNoteDialog()
    }
    note.loading.value = false
}
const closeNoteDialog = () => {
    selectedAction.value = ""
    note.note.value = ""
    note.showNoteDialog.value = false
}


const refreshData = (summaryDetails: boolean = true, historyList: boolean = true) => {
    if (summaryDetails) summaryDetailsComponent.value?.fetchSummaryDetail()
    if (historyList) historyListComponent.value?.fetchHistoryList()
}

const summaryDetailsComponent = ref()
const historyListComponent = ref()
const pageErrorMessage = ref("")

const fetchLabelsDropdownList = async () => {
    try {
        const response = await api.get('/labels/?applications=true');
        const items = response.data ? response.data : []
        labelList.value = items as Label[]
    }
    catch (error: any) {
        labelList.value = [];
        toast.error(getApiErrorMessage(error))
    }
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Report" })
    pageErrorMessage.value = message
    toast.error(message)
}

const rightPanelHeight = ref<number>(650)
const rightPanelRef = ref<HTMLElement | null>(null)
const leftPanelHeight = ref<number>(300)
const leftPanelRef = ref<HTMLElement | null>(null)
const setDynamicHeights = () => {
    const BUFFER = 45
    rightPanelHeight.value = calculateScrollHeight(rightPanelRef.value, BUFFER)
    leftPanelHeight.value = calculateScrollHeight(leftPanelRef.value, BUFFER, 270 /*minHeight*/)
}

const enableToolbar = computed(() => {
    return !(window.location.pathname.startsWith(APPVET_DETAILS_VIEW_ONLY_PATH))
})

const fetchDocumentList = async () => {
    await taxroll.fetchDocumentList(tru_id, loadFailureHandler)
}

const getDefaultSnoozeDays = async () => update.snoozeUntilDate.value = dayjs().add(await storeAdminDetails.getDefaultSnoozeDays(InternalSettings.application_default_snooze_days), "day").toDate()

onMounted(async () => {
    window.addEventListener("resize", setDynamicHeights)

    await storeProfile.fetchUserPermissions()
    await Promise.all([
        fetchLabelsDropdownList(),
        fetchUsersList(),
    ])
    fetchDocumentList()
    getDefaultSnoozeDays()
})

onUnmounted(async () => {
    window.removeEventListener("resize", setDynamicHeights)
})

const onSummaryLoaded = () => {
    setTimeout(setDynamicHeights, 10)
}

watch(() => update.snoozeUntilDate.value, (newDateValue) => {
    update.snoozeUntil.value = newDateValue?.toString() || ""
})

logPageView({ "pageName": "new-application-report", "itemId": tru_id })

</script>

<style>
@import "@/assets/appvet.css";
</style>