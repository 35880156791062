<template>
  <div class="container">
    <div>
      <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({name: 'onboardingConfigs'})">Data Config List</button>
      <input type="checkbox" class="mx-1" id="listed" :disabled="state.customersLoading"
        v-model="state.listed">
      <label for="listed" class="form-label">Listed Customers</label>
      <input type="checkbox" class="mx-1" id="exclude_qa" :disabled="state.customersLoading"
        v-model="state.exclude_qa">
      <label for="exclude_qa" class="form-label">Exclude QA Schemas</label>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Customer</th>
          <th scope="col">Customer ID</th>
          <th scope="col">Config</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="state.customersLoading">
          <td colspan="3" style="text-align: center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
        <tr v-else v-for="customer in state.customerConfigs" :key="customer.customer_id">
          <td>{{ customer.customer_name }}</td>
          <td>{{ customer.customer_id }}</td>
          <td>
            <div v-if="customer.configs.taxroll_config">
              <router-link :to="{name: 'onboardingConfig', params: { id: customer.configs.taxroll_config }}">Taxroll Config: {{ customer.configs.taxroll_config }}</router-link>
            </div>
            <div v-if="!customer.configs.taxroll_config">
              <router-link :to="{name: 'onboardingConfigs'}">No Taxroll Config Assigned</router-link>
            </div>
            <div v-if="customer.configs.digital_config">
              <router-link :to="{name: 'onboardingConfig', params: { id: customer.configs.digital_config }}">Digital Config: {{ customer.configs.digital_config }}</router-link>
            </div>
            <div v-if="!customer.configs.digital_config">
              <router-link :to="{name: 'onboardingConfigs'}">No Digital Config Assigned</router-link>
            </div>
            <div v-if="customer.configs.ocr_config">
              <router-link :to="{name: 'onboardingConfig', params: { id: customer.configs.ocr_config }}">OCR Config: {{ customer.configs.ocr_config }}</router-link>
            </div>
            <div v-if="!customer.configs.ocr_config">
              <router-link :to="{name: 'onboardingConfigs'}">No OCR Config Assigned</router-link>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({name: 'onboardingConfig', params: { id: 'new' }})">New Config</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { useAPI } from "@/helpers/services/api"
import {reactive, onMounted, watch} from "vue";
import type {
  CustomerConfigList
} from "@/helpers/interface/onboarding";
import { hidePFGetHelpButton } from "@/helpers/common";

const state = reactive({
  customerConfigs: [] as Array<CustomerConfigList>,
  customersLoading: false,
  listed: true,
  exclude_qa: true,
})

const api = useAPI()
const getCustomerConfigs = async () => {
  state.customersLoading = true;
  const response = await api.get(`onboarding/ingestions/configs`, {params: {listed: state.listed, exclude_qa: state.exclude_qa}});
  state.customerConfigs = response.data;
  state.customersLoading = false;
}

watch(() => [state.listed, state.exclude_qa], () => {
  getCustomerConfigs()
})

onMounted(async () => {
  await Promise.all([
    getCustomerConfigs()
  ])
  hidePFGetHelpButton()
});

</script>

<style scoped></style>