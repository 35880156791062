export const AdminConfig = [
    {
        "menu": "Workflow: Mail Module",
        "page": "workflow_mail_module",
        "url" : "mail-module",
        "requiredPermissions": [
            "read:postal_contact_workflows",
            "update:postal_contact_workflows",
            "create:postal_contact_workflows"
        ]
    },
    {
        "menu": "Config: Labels",
        "page": "config_labels",
        "url" : "labels",
        "requiredPermissions": [
            "create:labels"
        ]
    },
    {
        "menu": "Config: Mail Templates",
        "page": "config_mail_templates",
        "url" : "mail-templates",
        "requiredPermissions": [
            "update:mailing_templates",
            "create:mailing_templates"
        ]
    },
    {
        "menu": "Config: Change Customer",
        "page": "config_change_customer",
        "url" : "change-customer",
        "requiredPermissions": [
            "read:customers"
        ]
    },
    {
        "menu": "Config: Users",
        "page": "config_users",
        "url" : "users",
        "requiredPermissions": [
            "create:users",
            "update:users",
        ]
    },
    {
        "menu": "Config: Internal Settings",
        "page": "config_internal_settings",
        "url" : "internal-settings",
        "requiredPermissions": [
            "read:internal_setting",
            "update:internal_settings",
            "read:customers",
        ]
    },
    {
        "menu": "Config: Settings",
        "page": "config_client_settings",
        "url" : "settings",
        "requiredPermissions": [
            "update:client_settings",
        ]
    },
    {
        "menu": "Report: New Assessed Value Estimate",
        "page": "report_new_assessed_value",
        "url" : "report-nave",
        "requiredPermissions": [
            "read:reports_assessed_value"
        ]
    },
    {
        "menu": "New Assessed Value Estimate Request",
        "page": "report_new_assessed_value_request",
        "url" : "nave-request",
        "requiredPermissions": []
    },
    {
        "menu": "Report: Download Unclaimed List",
        "page": "dl_unclaimed_list",
        "url" : "dl-unclaimed-list",
        "requiredPermissions": [
            "read:reports_unclaimed"
        ]
    },
    {
        "menu": "Unclaimed Exemption Data Request",
        "page": "unclaimed_exmpt_data_req",
        "url" : "unclaimed-exmpt-data-request",
        "requiredPermissions": []
    },
    {
        "menu": "Onboarding",
        "page": "onboarding",
        "url" : "onboarding",
        "requiredPermissions": [
            "read:ingestion_configs",
            "update:ingestion_configs",
        ]
    }
]

export const SettingsConfig = [
    {
        "menu": "User Preferences",
        "page": "user_preference",
        "url" : "user-preference",
        "requiredPermissions": [
            "read:customers"        
        ]
    },
]