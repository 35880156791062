import type { StateIdExtraction } from "@/helpers/interface/general";

export enum OwnerType {
    Single = "single",
    Married = "married",
    Other = "other",
    Blank = ""
}

export enum ActiveDutyDocumentationType {
    MilitaryID = "Military ID",
    UtilityBill = "Utility Bill"
}

export interface AddressFieldsModel {
    address: string;
    unitNumber: string;
    city: string;
    state: string;
    postalCode: string;
    county?: string;
}

export interface ApplicationForm {
    appraisal_district_account_number: string;
    filing_late_application: boolean | null;
    start_year_of_exemption: number | null;
    exemption_general: boolean;
    exemption_disabled_person: boolean;
    exemption_age_65_plus: boolean;
    exemption_disabled_veteran: boolean;
    is_veteran_disability_permanent: boolean | null,
    exemption_surviving_spouse_armed_services: boolean;
    exemption_surviving_spouse_first_responder: boolean;
    exemption_donated_residence: boolean;
    percent_disability_rating: number | null;
    living_on_property: boolean | null;
    co_op_housing: boolean | null;
    co_op_housing_name: string | null;
    receiving_exemption_previous_residence: boolean | null;
    transferring_exemption_previous_residence: boolean | null;
    transferring_tax_limitation: boolean | null;
    previous_residence_address_line_1: string | null;
    previous_residence_unit_number: string | null;
    previous_residence_city: string | null;
    previous_residence_state: string | null;
    previous_residence_postal_code: string | null;
    previous_residence_county: string | null;
    property_owner_type_1: OwnerType;
    property_owner_name_1: string;
    property_owner_birthdate_1: Date | null;
    property_owner_id_number_1: string;
    property_owner_percent_ownership_interest_1: number | null;
    property_owner_email_1: string;
    property_owner_phone_1: string;
    property_owner_name_2: string | null;
    property_owner_birthdate_2: Date | null;
    property_owner_id_number_2: string | null;
    property_owner_percent_ownership_interest_2: number | null;
    property_owner_email_2: string | null;
    property_owner_phone_2: string | null;
    deceased_spouse_birthdate: Date | null;
    deceased_spouse_name: string | null;
    affirm_id_property_address_match: boolean | null;
    applicant_mailing_address_different: boolean | null;
    property_date_acquired: Date | null;
    property_date_occupied: Date | null;
    property_address_line_1: string;
    property_unit_number: string | null;
    property_city: string;
    property_state: string;
    property_postal_code: string;
    property_legal_description: string;
    applicant_mail_line_1: string | null;
    applicant_mail_unit_number: string | null;
    applicant_mail_city: string | null;
    applicant_mail_state: string | null;
    applicant_mail_postal_code: string | null;
    applicant_identified_on_deed: boolean | null;
    court_filing_number: string | null;
    is_heir_property: boolean | null;
    heir_property_owners_occupy_property: boolean | null;
    property_manufactured_home: boolean | null;
    manufactured_home_make: string;
    manufactured_home_model: string;
    manufactured_home_id: string;
    property_produces_income: boolean | null;
    percent_producing_income: string;
    property_acres_occupied: number | null;
    waive_reason_resident_of_facility: boolean;
    facility_name_address: string; // | null
    waive_reason_participator_address_confidential_program: boolean;
    waive_reason_active_duty: boolean;
    active_duty_documentation_type: string | null;
    waive_reason_special_drivers_license: boolean;
    additional_info: string;
    other_owned_properties: string;
    authorized_print_name: string;
    title_authorization: string;
    signature_of_applicant: string;
    id_details_property_owner_1: StateIdExtraction | null;
    id_details_property_owner_2: StateIdExtraction | null;
}


export interface ApplicationDataGA {
    tru_id: string;

    // property info
    parcel_number: string | null;
    property_date_purchased: string;

    // property address
    property_address_line_1: string;
    property_unit_number: string;
    property_city: string;
    property_state: string;
    property_postal_code: string;
    
    // residence
    is_primary_residence: boolean;
    residence_address_line_1: string;
    residence_unit_number: string;
    residence_city: string;
    residence_state: string;
    residence_postal_code: string;
    
    // owner 1
    owner_name: string;
    owner_address_line_1: string;
    owner_unit_number: string;
    owner_city: string;
    owner_state: string;
    owner_postal_code: string;
    owner_ssn4: string;  // last 4 digits of SSN
    owner_birthdate: string;
    owner_phone: string;
    owner_email: string;
    owner_voter_registration_county: string;
    
    // owner 1 continued
    is_resident_citizen_or_authorized: boolean | null;  // not required to answer
    legal_alien_registation: string;

    // owner 2
    owner2_name: string | null;
    owner2_address_line_1: string | null;
    owner2_unit_number: string | null;
    owner2_city: string | null;
    owner2_state: string | null;
    owner2_postal_code: string | null;
    owner2_ssn4: string | null;  // last 4 digits of SSN
    owner2_birthdate: string | null;
    owner2_phone: string | null;
    owner2_email: string | null;
    owner2_voter_registration_county: string | null;

    // owner continued
    is_applicant_100_percent_disabled: boolean;
    is_surviving_spouse_of_service_member: boolean;
    is_surviving_spouse_of_first_responder: boolean;
    is_disabled_since_jan1_and_below_income_threshold: boolean;

    // property continued
    is_property_used_for_business: boolean;
    property_business_use_description: string | null;
    is_property_rented: boolean;
    property_part_rented: string | null;

    // signature & date
    signature_of_applicant: string;
    signature_date: string;

    sectionC1: [
        {
            description: string;  // ie Line 1, etc
            applicant1: number;
            applicant2: number;
        }
    ] | null;

    sectionC2: [
        {
            description: string;  // ie Line 1, etc
            name: string;
            value: number;
        }
    ] | null;
}

export interface NamForm {
    id: string;
    customer_id: string;
    fips_code: string;
    name: string;
    state: string;
    api_key: string;
}
