<template>
    <main class="public-container">
        <noscript>This page requires JavaScript to be enabled in your browser.</noscript>
        <div class="content-wrapper">
            <div class="content" v-if="!formSubmitted">
                <form class="public-form" @submit.prevent="submitFormHandler">
                    <ApplicationSection title=" LGS-Homestead Rev 10-08" subtitle="Application for Homestead Exemption">
                        This is a placeholder
                    </ApplicationSection>
                   
                </form>
            </div>
            <div class="content text-center" v-else>
                <ApplicationSection title="LGS-Homestead Rev 10-08">
                    <p>
                        Your submission was successful.
                        <br>
                        <br>
                        Application ID: <b>{{ confirmationNumber }}</b>
                    </p>
                    <p>
                        <a href="#" @click="refreshForm()">Go back</a>
                    </p>
                </ApplicationSection>
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
import ApplicationSection from "@/components/ApplicationForm/ApplicationSection.vue"
import { computed, onMounted, ref, watch } from "vue"
import { generateUUIDv4, hidePFGetHelpButton } from "@/helpers/common"
import { getFileExtension, getFileSizeText, isValidFileType } from "@/helpers/files"
import { useApplicationForm } from "@/stores/applicationForm"
import { OwnerType, type NamForm, type ApplicationDataGA } from '@/helpers/interface/applicationForm';
import type { AxiosError } from "axios"
import { MAX_NOTE_LENGTH, getApiErrorMessage, setPageTitle } from "@/helpers/common"
import { toast } from "@/helpers/toast"
import { useRoute } from "vue-router"
import { useAPI } from "@/helpers/services/api"
import Message from 'primevue/message'
import { isValidEmail } from "@/helpers/regex"

const INVALID_EMAIL_MESSAGE = "You must enter a valid email address. Example: name@example.com"
const publicAPI = useAPI({ authGuard: false })
const route = useRoute()
const storeApplicationForm = useApplicationForm()
const ACCEPT_FILE_TYPES = ".png, .jpg, .jpeg, .bmp, .gif, .pdf"
const today = new Date()
const todayISOString = today.toISOString().substring(0, 10)

const formSubmitted = ref(false)
const formFields = computed(() => (storeApplicationForm.fields))
const uploadState = computed(() => (storeApplicationForm.uploadState))


/* this key must be included in all HTTP requests to the server as a header
    for file uploads and form submission itself
*/
const instanceKey = ref("")
instanceKey.value = generateUUIDv4()
const confirmationNumber = ref(0)
const formID = route.params.form_id as string
const form = ref({
    id: formID,
    customer_id: '',
    name: '',
    state: '',
    fips_code: '',
    api_key: '',
} as NamForm)

const isFormLoaded = computed(() => {
    return form.value.customer_id !== ''
})

const NOT_VALID_UUID_ERR_MESSAGE = "Error: The form cannot be submitted due to an invalid ID. Please report this issue to the county."
const istValidUUID = ref<Boolean | null>(null)
const loadNamForm = async (formID: string) => {
    istValidUUID.value = true
    try {
        const response = await publicAPI.get(`/applications/form/${formID}`)
        form.value = response.data
    } catch (error) {
        istValidUUID.value = false
        toast.error(NOT_VALID_UUID_ERR_MESSAGE, {
            duration: 0,
            dismissible: false,
            position: "top-right"
        });
    }
}

const getRequiredAttrValue = (value: boolean | null): boolean => (value === true)



const fileInputChangeHandler = (e: Event, isDriversLicense: boolean = false) => {
    const maxMegaBytes = isDriversLicense ? 10 : 20
    const MAX_FILE_SIZE_BYTES = maxMegaBytes * 1024 * 1024  // MB converted by bytes
    const input = e.target as HTMLInputElement
    if (!input.files)
        return;
    const selectedFile = input.files[0]

    if (selectedFile) {
        if (!isValidFileType(selectedFile.name, ACCEPT_FILE_TYPES.split(","))) {
            const extension = getFileExtension(selectedFile.name)
            alert(`Files of type '${extension.toUpperCase()}'' may not be uploaded.`)
            input.value = "";
            return;
        }
        if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
            const fileSizeText = getFileSizeText(selectedFile.size)
            const maxSizeText = getFileSizeText(MAX_FILE_SIZE_BYTES)
            alert(`The selected file is ${fileSizeText}, which is larger than the maximum allowed (${maxSizeText}).`)
            input.value = "";
            return;
        }
        if (selectedFile.size === 0) {
            alert("The selected file contains no data and may not be uploaded.");
            input.value = "";
            return;
        }
    }
}

const elementRequiredHandler = (e: Event) => {
    const input = e.target as HTMLInputElement;
    let container: any = input.closest("fieldset");
    if (!container) {
        container = input.parentNode as HTMLElement;
    }
    if (!container)
        throw "Unable to process required element because it's not attached to the DOM"

    let displayText = "This field is required";
    switch (input.type) {
        case "radio":
            displayText = "Please choose an option"
            break;
        case "file":
            displayText = "You must upload a file"
            break;
        case "tel":
            displayText = input.getAttribute("title")?.toString() || "You must follow this format (555-123-4567)"
            break;
        case "email":
            displayText = input.getAttribute("title")?.toString() || INVALID_EMAIL_MESSAGE
            break;
        case "number":
            displayText = `Value must be a number between ${input.getAttribute("min")} and ${input.getAttribute("max")}`
            if ((~~input.step) > 1) {
                displayText += ` in increments of ${input.step}`
            }
            break;
    }
    displayInvalidField(container, displayText)
}

const displayInvalidField = (target: HTMLElement, text: string) => {
    const INVALID = "public-field-invalid";

    target.setAttribute("data-invalid-text", text)
    target.classList.add(INVALID);
    target.scrollIntoView()

    setTimeout(() => {
        target.setAttribute("data-invalid-text", "")
        target.classList.remove(INVALID)
    }, 5000);
}

const convertBase64ToFile = (base64: string, filename: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new File([byteArray], filename, { type: contentType })
}

const getEffectiveDate = () => {
    return `${formFields.value.start_year_of_exemption}-01-01`
}


const uploadSignature = async (base64Signature: string) => {
    return await storeApplicationForm.uploadFile(
        instanceKey.value,
        convertBase64ToFile(base64Signature, "signature.png", "image/png"),
        form.value.customer_id!,
        "application_attachments",
        "signature_of_applicant"
    )
}

const createSubmissionPayload = async () => {
    const payload = { ...formFields.value } as any

    Object.keys(payload).forEach(key => {
        // convert all dates (from PrimeVue datepicker) to ISO strings
        if (payload[key] instanceof Date) {
            if (isNaN(+payload[key])) {
                payload[key] = null
            } else {
                payload[key] = payload[key].toISOString().substring(0, 10)
            }
        }
        if (payload[key] === "") {
            payload[key] = null;
        }
    })

    const idSeed = instanceKey.value.slice(0, 4)

    payload['tru_id'] = instanceKey.value
    payload['application_id'] = parseInt(idSeed, 16).toString()
    payload['form_id'] = form.value.id
    payload['effective_date'] = getEffectiveDate()
    delete payload['start_year_of_exemption']


    const sigUpl = await uploadSignature(payload['signature_of_applicant'])
    payload['signature_of_applicant'] = sigUpl?.id

    return payload
}

const submitFormHandler = async () => {
    // if (!atLeastOneExemptionSelected.value) {
    //     const target = document.getElementById("general-exemption-container") as HTMLElement
    //     displayInvalidField(target, "You must choose at least one type of exemption")
    //     return;
    // }
    if (formFields.value.signature_of_applicant.length === 0) {
        const target = document.getElementById("signature-container") as HTMLElement
        displayInvalidField(target, "Your signature is required")
        return;
    }
    if (!isValidEmail(formFields.value.property_owner_email_1)) {
        const input = document.getElementById("property-owner-email-1") as HTMLElement
        let container: any = input.closest("fieldset");
        if (!container) container = input.parentNode as HTMLElement;
        if (container) {
            displayInvalidField(container, INVALID_EMAIL_MESSAGE)
            return;
        }
    }
   

    if (uploadState.value > 0) {
        toast.info("Hold tight, we're processing your upload.")
        return;
    }
    
    storeApplicationForm.getOrSetFormLoading(true)
    const payload = await createSubmissionPayload()

    try {
        const resp = await publicAPI.post(
            `/applications/${form.value.fips_code}`,
            payload,
            {
                "headers": {
                    "Content-Type": "application/json",
                    "X-Api-Key": form.value.api_key,
                }
            }
        )
        if (resp.data && resp.data.application_id) {
            confirmationNumber.value = resp.data.application_id as number
        }
        formSubmitted.value = true
        AppState.clear()
    } catch (error) {
        toast.error(getApiErrorMessage(error as AxiosError))
    }
    storeApplicationForm.getOrSetFormLoading(false)
}


const uploadFiles = async (e: Event) => {
    if (!isFormLoaded.value) {
        await loadNamForm(formID)
    }
    if (!isFormLoaded.value) {
        throw new Error("Unauthorized form")
    }
    fileInputChangeHandler(e)
    const input = e.target as HTMLInputElement
    if (!input.files) return;
    const file = input.files && input.files[0]
    const id = input.getAttribute("id")?.toString() || ""
    await storeApplicationForm.uploadFile(
        instanceKey.value,
        file,
        form.value.customer_id!,
        "application_attachments",
        id,
        input
    )
}

const refreshForm = () => window.location.reload()




const AppState = {
    KEY: storeApplicationForm.STATE_STORAGE_KEY,

    clear: () => {
        try {
            sessionStorage.removeItem(AppState.KEY)
        } catch (ex) {
            console.warn("Unable to clear form state from storage")
        }
    },

    save: () => {
        try {
            if (isFormLoaded.value) {
                sessionStorage.setItem(AppState.KEY, JSON.stringify(formFields.value));
            }
        } catch (ex) {
            console.warn("Unable to utilize storage to persist form state.")
        }
    },

    events: {
        windowBeforeUnload: () => {
            if (formSubmitted.value) {
                return;
            }
            return "Are you sure you want to leave this page? Your progress may be lost."
        }
    }

}


watch(formFields.value, AppState.save, { deep: true });

const isADAccountNumDisabled = computed(() => !!route.query.account_num)

onMounted(async () => {
    setPageTitle("Application for Homestead Exemption")
    formFields.value.appraisal_district_account_number = (route.query.account_num as string) || ""
    await loadNamForm(formID)
    window.onbeforeunload = AppState.events.windowBeforeUnload
    hidePFGetHelpButton()
})


</script>


<style>
@import "@/assets/public/public-base.css";
</style>
