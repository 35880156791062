import { ref } from "vue"
import { defineStore } from "pinia";
import dayjs from "dayjs";
import { type ApplicationForm, OwnerType } from "@/helpers/interface/applicationForm"
import axios from "axios"
import { getApiErrorMessage } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import { useAPI } from "@/helpers/services/api";
import type { Upload } from "@/helpers/interface/uploads";
import type { StateIdExtraction } from "@/helpers/interface/general";

export const useApplicationForm = defineStore("applicationForm", () => {
     const initialFormData = {
        appraisal_district_account_number: "",
        filing_late_application: null,
        start_year_of_exemption: null,
        exemption_general: false,
        exemption_disabled_person: false,
        exemption_age_65_plus: false,
        exemption_disabled_veteran: false,
        is_veteran_disability_permanent: null,
        exemption_surviving_spouse_armed_services: false,
        exemption_surviving_spouse_first_responder: false,
        exemption_donated_residence: false,
        percent_disability_rating: null,
        living_on_property: null,
        co_op_housing: null,
        co_op_housing_name: "",
        receiving_exemption_previous_residence: null,
        transferring_exemption_previous_residence: null,
        transferring_tax_limitation: null,
        previous_residence_address_line_1: null,
        previous_residence_unit_number: null,
        previous_residence_city: null,
        previous_residence_state: null,
        previous_residence_postal_code: null,
        previous_residence_county: null,
        property_owner_type_1: OwnerType.Blank,
        property_owner_name_1: "",
        property_owner_birthdate_1: null,
        property_owner_id_number_1: "",
        property_owner_percent_ownership_interest_1: null,
        property_owner_email_1: "",
        property_owner_phone_1: "",
        property_owner_name_2: null,
        property_owner_birthdate_2: null,
        property_owner_id_number_2: null,
        property_owner_percent_ownership_interest_2: null,
        property_owner_email_2: null,
        property_owner_phone_2: null,
        deceased_spouse_birthdate: null,
        deceased_spouse_name: null,
        affirm_id_property_address_match: null,
        applicant_mailing_address_different: null,
        property_date_acquired: null,
        property_date_occupied: null,
        property_address_line_1: "",
        property_unit_number: null,
        property_city: "",
        property_state: "",
        property_postal_code: "",
        property_legal_description: "",
        applicant_mail_line_1: null,
        applicant_mail_unit_number: null,
        applicant_mail_city: null,
        applicant_mail_state: null,
        applicant_mail_postal_code: null,
        applicant_identified_on_deed: null,
        court_filing_number: null,
        is_heir_property: null,
        heir_property_owners_occupy_property: null,
        property_manufactured_home: null,
        manufactured_home_make: "",
        manufactured_home_model: "",
        manufactured_home_id: "",
        property_produces_income: null,
        percent_producing_income: "",
        property_acres_occupied: null,
        waive_reason_resident_of_facility: false,
        facility_name_address: "",
        waive_reason_participator_address_confidential_program: false,
        waive_reason_active_duty: false,
        active_duty_documentation_type: "",
        waive_reason_special_drivers_license: false,
        additional_info: "",
        other_owned_properties: "",
        authorized_print_name: "",
        title_authorization: "",
        signature_of_applicant: "",
        id_details_property_owner_1:  null,
        id_details_property_owner_2: null,
    }

    const STATE_STORAGE_KEY = "myApplicationTx50114"
    const dateFields = [
        "property_owner_birthdate_1",
        "property_owner_birthdate_2",
        "deceased_spouse_birthdate",
        "property_date_acquired",
        "property_date_occupied"
    ]
    try {
        const jsonString = sessionStorage.getItem(STATE_STORAGE_KEY);
        if (jsonString) {
            console.log("Restoring form state....")
            const parsedData = JSON.parse(jsonString, (key, value) => {
                if (dateFields.includes(key) && typeof value === "string") {
                    return new Date(value);
                }
                return value;
            });
            // nosemgrep - working with local data only
            Object.assign(initialFormData, parsedData);
        } else {
            console.log("Initializing state with defaults...")
        }
    } catch (ex) {
        console.warn("Failed to restore state from storage", ex)
    }
    const fields = ref<ApplicationForm>(initialFormData)

    const formLoading = ref(false)
    const uploadState = ref<number>(0)
    const publicApi = useAPI({authGuard: false})
    

    const getOrSetFormLoading = (value?: boolean) => {
        if (value !== undefined) formLoading.value = value
        return formLoading.value;
    }

    const showBirthDateWarning = (): boolean => {
        // Rules: age >= 65 AND 'person age 65 or older' is unchecked
        // then show warning
        const today = dayjs();
        const age = today.diff(fields.value.property_owner_birthdate_1, "year")
        return (!fields.value.exemption_age_65_plus && (age >= 65))
    }

    const extractText = async (applicationID: string, uploadID: string, api_key: string) => {
        const payload = {
            "tru_id": applicationID, 
            "upload_id": uploadID
        }
        const options = {
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": api_key,
            }
        }
        try {
            const response = await axios.post("applications/extract_text", payload, options) 
            return response ? response.data as StateIdExtraction : null
        } catch( ex ) {
            return null;
        }
    }

    const uploadFile = async (instanceKey: string, file: File, customerID: string, itemID: string = "application_attachments", feedbackElementID: string = "attachments", input: HTMLInputElement | null = null, api_key: string | null = null): Promise<Upload | null>  => {
        if (input && input.value.length <= 0) {
            uploadFileMessage(input, feedbackElementID, { message:"No file was selected.", status: 2 })
            return null
        }
        if(input) {
            uploadFileMessage(input, feedbackElementID, { message: "Loading", status: 0 })
        }
        const applicationID = instanceKey
        // 1 create upload - get put url
        // 2 put content
        // 3 mark upload as completed
        const resp = await publicApi.post('/uploads/', {
            tru_id: applicationID,
            customer_id: customerID,
            item_id: itemID,
            file_name: file.name,
            file_size: file.size,
            content_type: file.type,
            meta: {
                application_id: applicationID,
                ...feedbackElementID ? {document_type: feedbackElementID} : {}
            }
          }
        )
        const upload: Upload  = resp.data
        const uploadCfg = upload.upload_url!
        
        uploadState.value += 1
        const uplRes = await axios.request({
            ...uploadCfg,
            data: file,
            validateStatus: () => true
        })
        
        if (uplRes.status !== 200) {
            const message = getApiErrorMessage({response: uplRes} as any)
            toast.error(message)
            if (input) {
                uploadFileMessage(input, feedbackElementID, { message: message, status: 2 })
            }
            await publicApi.delete(`/uploads/${upload.id}`)  // deleting failed upload
        } else {
            await publicApi.post(`/uploads/${upload.id}/complete`);

            const regex = /^property-owner-id-file-([1-2])$/;
            const match = feedbackElementID.match(regex);

            if (match && api_key) {
                const ownerIndex = match[1];
                const MAX_SIZE_BYTES = 10 * 1024 * 1024;

                if (file.size < MAX_SIZE_BYTES) {
                    const extractionResult = await extractText(applicationID, upload.id, api_key);
                    if (extractionResult) {
                        if (ownerIndex === '1') {
                            fields.value.id_details_property_owner_1 = extractionResult;
                        } else if (ownerIndex === '2') {
                            fields.value.id_details_property_owner_2 = extractionResult;
                        }
                        upload.meta.extraction = extractionResult;
                    }
                } else {
                    console.warn(`File size of ${file.size} bytes exceeds the maximum permitted size for text extraction of ${MAX_SIZE_BYTES} bytes`)
                }
            }
            if (input) {
                uploadFileMessage(input, feedbackElementID, { message: `Successfully uploaded file with the filename: '${upload.file_name}'`, status: 1 })
            }
        }
        uploadState.value -=1
        return upload
    }

    const uploadFileMessage = (input: HTMLInputElement, elementID: string, response: { message: string; status: number }) => {
        const fileUploadStatusID = elementID + "-upload-status"
        const fileUploadStatusSuccess = document.getElementById(fileUploadStatusID + "-1") as HTMLDivElement
        const fileUploadStatusSuccessMessage = document.getElementById(fileUploadStatusID + "-1-message") as HTMLDivElement
        const fileUploadStatusFailed = document.getElementById(fileUploadStatusID + "-2") as HTMLDivElement
        const fileUploadStatusFailedMessage = document.getElementById(fileUploadStatusID + "-0-message") as HTMLDivElement
        const fileUploadStatusLoading= document.getElementById(fileUploadStatusID + "-0") as HTMLDivElement
    
        if (response.status === 1) { // Success
            if (fileUploadStatusSuccess) {
                fileUploadStatusSuccess.style.display = "inline-block"
                fileUploadStatusFailed.style.display = "none"
                fileUploadStatusLoading.style.display = "none"
                fileUploadStatusSuccessMessage.textContent = response.message
            }
        }
        else if (response.status === 2) { // Failed
            if (fileUploadStatusFailed) {
                fileUploadStatusSuccess.style.display = "none"
                fileUploadStatusFailed.style.display = "inline-block"
                fileUploadStatusLoading.style.display = "none"
                fileUploadStatusFailedMessage.textContent = response.message
    
                // If there is an error, remove the
                // file in the input field
                input.value = ""
            }
        }
        else if (response.status === 0) { // Loading
            if (fileUploadStatusFailed) {
                fileUploadStatusSuccess.style.display = "none"
                fileUploadStatusFailed.style.display = "none"
                fileUploadStatusLoading.style.display = "inline-block"
            }
        }
    }

    return {
        STATE_STORAGE_KEY,
        initialFormData,
        fields,
        getOrSetFormLoading,
        showBirthDateWarning,
        uploadFile,
        uploadFileMessage,
        uploadState
    }
})