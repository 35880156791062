<template>
    <div id="parties">
        <div v-if="!fetchPartiesLoading">
            <table class="table" v-if="parties?.length">
                <tbody v-for="party in parties" :key="party.email">
                    <tr class="applicant-name">
                        <td colspan="2">
                            {{ party.applicant_name }}
                        </td>
                    </tr>
                    <tr>
                        <td class="label">Birthdate</td>
                        <td class="p-1 align-top value">{{ formatDate(party?.dob) }}</td>
                    </tr>
                    <tr>
                        <td class="label">Identity Document</td>
                        <td class="p-1 align-top value">
                            <div v-if="!fetchDocumentListLoading">
                                <div v-if="getIdentityDocument(party.document_type)">
                                    <!-- For image file -->
                                    <a v-if="isImageFile(getIdentityDocument(party.document_type).file_name)"
                                        href="javascript:;" class="file-name-link"
                                        @click="imageClick(getIdentityDocument(party.document_type).file_name)">
                                        {{ getIdentityDocument(party.document_type).file_name }}
                                    </a>

                                    <!-- For non-image file -->
                                    <a v-else :href="getIdentityDocument(party.document_type).download_url"
                                        target="_blank" class="file-name-link">
                                        {{ getIdentityDocument(party.document_type).file_name }}
                                    </a>
                                </div>
                                <div v-else>
                                    {{ party?.identity_document }}
                                </div>
                            </div>
                            <loading-icon class="d-inline ml-2" v-else />
                        </td>
                    </tr>
                    <tr>
                        <td class="label">Document Number</td>
                        <td class="p-1 align-top value">{{ party?.document_number }}</td>
                    </tr>
                    <tr>
                        <td class="label">Address</td>
                        <td class="p-1 align-top value">
                            {{ getFormattedAddress(null, party?.address, party?.city, party?.state,
                                party?.postal_code) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="label">Email</td>
                        <td class="p-1 align-top value">{{ party?.email }}</td>
                    </tr>
                    <tr>
                        <td class="label">Phone</td>
                        <td class="p-1 align-top value">{{ party?.phone }}</td>
                    </tr>
                    <tr>
                        <td class="label">Ownership Interest</td>
                        <td class="p-1 align-top value">{{ party?.ownership_interest }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table" v-else>
                <tbody>
                    <tr>
                        <td class="text-center">No records to display</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <table class="table">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <Skeleton height="1.2rem" width="100%"></Skeleton>
                        </td>
                    </tr>
                    <tr v-for="i in 6" :key="i">
                        <td>
                            <Skeleton height="1.2rem" width="120px"></Skeleton>
                        </td>
                        <td>
                            <Skeleton height="1.2rem" width="250px"></Skeleton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Galleria v-model:visible="isGalleriaVisible" v-model:activeIndex="activeIndex" :value="galleriaItems"
            :numVisible="7" :thumbnailsPosition="'bottom'" containerStyle="max-width: 60%" :circular="true"
            :fullScreen="true" :showItemNavigators="true">
            <template #item="slotProps">
                <img :src="slotProps.item.image.src" :alt="slotProps.item.image.alt" @load="onGalleriaImageRender" />

                <div class="tr-galleria-extra-toolbar">
                    <button type="button" @click="handleGalleriaImageZoomOut" aria-label="Zoom Out"
                        v-tooltip="'Zoom Out'">
                        <i class="fa fa-search-minus" aria-hidden="true"></i>
                    </button>
                    <button type="button" @click="handleGalleriaImageZoomIn" aria-label="Zoom In" v-tooltip="'Zoom In'">
                        <i class="fa fa-search-plus" aria-hidden="true"></i>
                    </button>
                    <button type="button" @click="handleGalleriaImageRotate(90)" aria-label="Rotate right"
                        v-tooltip="'Rotate Right'">
                        <i class="fa fa-rotate-right" aria-hidden="true"></i>
                    </button>
                    <button type="button" @click="handleGalleriaImageRotate(-90)" aria-label="Rotate left"
                        v-tooltip="'Rotate Left'">
                        <i class="fa fa-rotate-left" aria-hidden="true"></i>
                    </button>
                </div>
            </template>
            <template #thumbnail="slotProps">
                <img :src="slotProps.item.image.src" :alt="slotProps.item.image.alt" style="display: block" />
            </template>
            <template #caption="slotProps">
                <a :href="slotProps.item.image.src" target="_blank" class="text-white">
                    <span class="fa fa-download me-1"></span> Download | {{ slotProps.item.filename }}
                </a>
            </template>
        </Galleria>
    </div>

</template>

<script setup lang="ts">
import type { Parties } from "@/helpers/interface/appvet"
import type { AxiosError } from "axios"
import { computed, onMounted, ref, watch } from "vue";
import { useAPI } from "@/helpers/services/api"
import Skeleton from "primevue/skeleton"
import { getApiErrorMessage, getFormattedAddress, formatDate, galleriaThumbnailButtons, getQueryParams, isImageFile } from "@/helpers/common";
import { toast } from "@/helpers/toast";
import { useTaxroll } from "@/stores/taxroll";
import Galleria from 'primevue/galleria';
import {
    onGalleriaImageRender,
    handleGalleriaImageZoomIn,
    handleGalleriaImageZoomOut,
    handleGalleriaImageRotate,
} from "@/helpers/galleria"
import LoadingIcon from "@/components/Shared/LoadingIcon.vue";

const props = defineProps({
    tru_id: {
        type: String,
        required: true
    }
})

const api = useAPI()
const taxroll = useTaxroll()
const fetchPartiesLoading = ref(true)
const parties = ref<Parties[] | null>(null)
const fetchDocumentListLoading = computed(() => taxroll.getDocumentListLoadingState)
const documentList = computed(() => taxroll.getDocumentList)
const galleriaItems = ref<any[]>([])

const activeIndex = ref(0)
const isGalleriaVisible = ref(false)
const imageClick = (file_name: string) => {
    isGalleriaVisible.value = true
    activeIndex.value = galleriaItems.value?.findIndex(doc => doc.filename === file_name) as number
    galleriaThumbnailButtons()
}

const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error)
    toast.error(message)
}
const fetchParties = async () => {
    const tru_id = props.tru_id
    fetchPartiesLoading.value = true

    try {
        const { data } = await api.get(`applications/${tru_id}/parties`)
        parties.value = data
    } catch (error: unknown) {
        const err = error as AxiosError
        loadFailureHandler(err)
    }

    fetchPartiesLoading.value = false
}

const getIdentityDocument = (documentType: string | null) => (documentList.value.filter((docs: any) => docs.document_type === documentType)?.[0] || null)

watch(() => documentList.value, async (documentList) => {
    const dataImages = documentList?.filter(item => item.content_type.startsWith('image/'))
    if (!dataImages?.length) return
    let images = []
    for (const item of dataImages) {
        const img = new Image()
        img.src = item.download_url
        img.alt = item.file_name
        images.push({
            image: img,
            filename: item.file_name,
            expirationDate: getQueryParams(item.download_url).get("Expires") || "",
            url: item.download_url
        })
    }
    galleriaItems.value = images
})

onMounted(() => {
    fetchParties()
})

</script>

<style scoped>
#parties .applicant-name {
    text-align: center;
    background-color: var(--main-green-color);
    color: #fff;
    text-transform: uppercase;
}
</style>